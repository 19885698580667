import styled from "@emotion/styled"
import * as React from "react"

import Layout from "../components/layout"
import Seo from "../components/seo"
const logo = require("../images/logo.svg").default

export const Paragraph = styled("p")`
  position: absolute;
  width: 100%;
  font-family: Marcellus;
  font-style: normal;
  font-weight: normal;
  font-size: 20px;
  text-align: center;
  color: #000000;
  top: 50vh;
`

const ErrorPage = () => (
  <Layout
    backgroundColor="white"
    logo={logo}
    menuBGColor="black"
    hamburgerColor="black"
    footerColor="#171b32"
    hideFooter
  >
    <Seo title="Rajyashree Ramesh" />
    <Paragraph>This page doesnot exist</Paragraph>
  </Layout>
)

export default ErrorPage
